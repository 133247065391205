import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "playlistCard"]

  connect() {
    this.showSpinnerForNavigation = false

    this.handleTurboVisit = this.handleTurboVisit.bind(this)
    this.hideSpinner = this.hideSpinner.bind(this)

    window.addEventListener("turbo:visit", this.handleTurboVisit)
    window.addEventListener("turbo:load", this.hideSpinner)
    window.addEventListener("turbo:render", this.hideSpinner)
  }

  disconnect() {
    window.removeEventListener("turbo:visit", this.handleTurboVisit)
    window.removeEventListener("turbo:load", this.hideSpinner)
    window.removeEventListener("turbo:render", this.hideSpinner)
  }

  handleCardClick() {
    this.showSpinnerForNavigation = true
  }

  handleTurboVisit() {
    if (this.showSpinnerForNavigation) {
      this.showSpinner()
    }
    this.showSpinnerForNavigation = false
  }

  showSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove("hidden")
    }
  }

  hideSpinner() {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add("hidden")
    }
  }
}
